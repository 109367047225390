import "../styles/app.css"
import { Helmet } from 'react-helmet';
import Welcome from "../components/home";
import i18n from '../i18n';
import { useTranslation } from "react-i18next";
import en from '../assets/translations/en.json';
import fr from '../assets/translations/fr.json';

function Home (props) {
    if(props.language !== i18n.language){
        i18n.changeLanguage(i18n.language === 'fr' ? 'en' : 'fr')
    }

    let bgImg = process.env.PUBLIC_URL + "pictures/home_3.jpg";
    let topPic = process.env.PUBLIC_URL + "pictures/jean_andre.jpg";
    
    const { t } = useTranslation();
         
    return (
        <div className='home-body' style={{
            backgroundImage: `url(${bgImg})`,
            }
        }
        >   
            <Helmet>
                <html lang={i18n.language} />
                <title>{t("home_title")}</title>
                <meta name="Description" content={t("meta_description")} />
                <meta name="Keywords" content={t("meta_keywords")} />
                <link rel="schema.DC" href="http://purl.org/dc/elements/1.1/" />
                <meta name="DC.title" content={t("meta_title")} />
                <meta name="DC.Description" content={t("meta_description")} />
                <meta name="DC.Subject" content={t("meta_keywords")} />
                <meta name="DC.format" content="text/html" />
                <meta name="DC.language" scheme="RFC3066" content={ i18n.language === 'fr' ?  "fr-ca" : "en-ca"} />
                <meta name="Author" content={t("company_title")} />
                <meta name="Copyright" content={t("company_title")} />
                <meta name="Revisit-After" content="10 days" />
                <meta name="robots" content="all, noodp, noydir" />s
                <meta name="Date-Revision-yyyymmdd" content="20230521" />
                <meta content={i18n.language} http-equiv="content-language" />
            </Helmet>
            {
                window.location.pathname==="/" ?
                <Helmet>
                    <link href={"https://helinov.ca/"} title={fr.company_title + " - " + fr.home_title} rel="start" />
                    <link href={"https://helinov.ca/ecole"} title={fr.company_title + " - " + fr.school_title} rel="next" />
                    <link href={"https://helinov.ca/forets"} title={fr.company_title + " - " + fr.forest_title} rel="next" />
                    <link href={"https://helinov.ca/deglacage"} title={fr.company_title + " - " + fr.deicing_title} rel="next" />
                    <link href={"https://helinov.ca/construction"} title={fr.company_title + " - " + fr.construction_title} rel="next" />
                    <link href={"https://helinov.ca/red"} title={fr.company_title + " - " + fr.rd_title} rel="next" />
                    <link href={"https://helinov.ca/contactez"} title={fr.company_title + " - " + fr.contact_title} rel="next" />
                </Helmet>
                :
                <Helmet>
                    <link href={"https://helinov.ca/home"} title={en.company_title + " - " + en.home_title} rel="start" />
                    <link href={"https://helinov.ca/school"} title={en.company_title + " - " + en.school_title} rel="next" />
                    <link href={"https://helinov.ca/forests"} title={en.company_title + " - " + en.forest_title} rel="next" />
                    <link href={"https://helinov.ca/deicing"} title={en.company_title + " - " + en.deicing_title} rel="next" />
                    <link href={"https://helinov.ca/work"} title={en.company_title + " - " + en.construction_title} rel="next" />
                    <link href={"https://helinov.ca/r+d"} title={en.company_title + " - " + en.rd_title} rel="next" />
                    <link href={"https://helinov.ca/contact"} title={en.company_title + " - " + en.contact_title} rel="next" />
                </Helmet>
            }
            <Welcome title='home' imgFloat='left' pic={topPic} isHeader={true} />
        </div>
    );
};

export default Home;