import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import "../styles/navbar.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { useTranslation } from "react-i18next";
import i18n from '../i18n';

function TopMenu() {
  const { t } = useTranslation();
  const logo = process.env.PUBLIC_URL+'images/logo-helinov-b&w.png'

  const page_fr = ["/","/nous","/ecole","/foret","/deglacage","/construction","/red","/actualites","/contactez"]

  if(page_fr.includes(window.location.pathname)){
    if('fr' !== i18n.language){
      i18n.changeLanguage('fr')
    }
  }else{
    if('en' !== i18n.language){
      i18n.changeLanguage('en')
    }
  }
  
  return (

    <Navbar collapseOnSelect expand="md" >
      <Container>
        {/*<Navbar.Brand href="/">{t("company_title")}</Navbar.Brand>*/}
        <Navbar.Brand href={t("home_page")} ><img style={{top: '0', marginTop: '-12px', marginLeft:'-125px', boxShadow: '0 0 0 0', height:'55px', objectFit:"scale-down"}} src={logo} alt="logo-helinovations"/></Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav">
            <span className="toggler-icon top-bar"></span>
            <span className="toggler-icon middle-bar"></span>
            <span className="toggler-icon bottom-bar"></span>
        </Navbar.Toggle>
        <Navbar.Collapse id="responsive-navbar-nav" >
          <Nav className="nav" >
            <Nav.Link href={t("school_page")}>{t("school_title")}</Nav.Link>
            {<NavDropdown title={t("operations_title")} id="operations-dropdown" >
              <NavDropdown.Item href={t("forest_page")}>{t("forest_title")}</NavDropdown.Item>
              <NavDropdown.Item href={t("deicing_page")}>{t("deicing_title")}</NavDropdown.Item>
              <NavDropdown.Item href={t("construction_page")}>{t("construction_title")}</NavDropdown.Item>
              <NavDropdown.Item href={t("rd_page")}>{t("rd_title")}</NavDropdown.Item>
              {/*<NavDropdown.Divider />
              <NavDropdown.Item href="/action/3.4">
                Separated link
              </NavDropdown.Item>*/}
            </NavDropdown>
          }
            <Nav.Link href={t("contact_page")}>{t("contact_title")}</Nav.Link>
          </Nav>
          {/*<Nav>
            <Nav.Link key={lng} eventKey={lng} onClick={changeLanguage} >
              {lng === "en" ? "Français" : "English"}
            </Nav.Link>
          </Nav>
        */}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default TopMenu;