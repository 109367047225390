import React from "react";
import Article from "../components/articles";
import { Helmet } from 'react-helmet';
import { useTranslation } from "react-i18next";
import i18n from '../i18n';

function Operations (props) {
    if(props.language !== i18n.language){
        i18n.changeLanguage(i18n.language === 'fr' ? 'en' : 'fr')
    }
    const { t } = useTranslation();
    const picPath = process.env.PUBLIC_URL;
    var icePic = picPath + "pictures/ice_1.jpg";
    return (
        <div className='page-body' >
            <Helmet>
                <html lang={i18n.language} />
                <title>{t("company_title") + " - " + t("deicing_title")}</title>
                <meta name="Description" content={t("meta_description")} />
                <meta name="Keywords" content={t("meta_keywords")} />
                <link rel="schema.DC" href="http://purl.org/dc/elements/1.1/" />
                <meta name="DC.title" content={t("deicing_title")} />
                <meta name="DC.Description" content={t("meta_description")} />
                <meta name="DC.Subject" content={t("meta_keywords")} />
                <meta name="DC.format" content="text/html" />
                <meta name="DC.language" scheme="RFC3066" content={ i18n.language === 'fr' ?  "fr-ca" : "en-ca"} />
                <meta content={i18n.language} http-equiv="content-language" />
            </Helmet>
            {
                window.location.pathname==="/deglacage" ?
                <Helmet>
                    <link href={"https://helinov.ca/deglacage"}  rel="canonical" />
                </Helmet>
                :
                <Helmet>
                    <link href={"https://helinov.ca/deicing"} rel="canonical" />
                </Helmet>
            } 
            <Article title='deicing' imgFloat='left' pic={icePic} picSize='60%' isHeader={true} />
        </div>
    );
};

export default Operations;