import "../styles/footer.css";
import { IconContext } from "react-icons";
import { MdCopyright, MdFacebook, MdLocationOn } from "react-icons/md"
import { FaTiktok, FaYoutube } from "react-icons/fa"
import { GiRotaryPhone } from "react-icons/gi"
import i18n from '../i18n';
/*import { useTranslation } from "react-i18next";*/

function Footer () {
    /*const { t } = useTranslation();*/
    const changeLanguage = () => {
        const pageRel = {
            "/": "/home",
            "/home": "/",
            "/about": "/nous",
            "/nous" : "/about",
            "/school" : "/ecole",
            "/ecole" : "/school",
            "/forest" : "/foret",
            "/foret" : "/forest",
            "/deicing" : "/deglacage",
            "/deglacage" : "/deicing",
            "/work" : "/construction",
            "/construction" : "/work",
            "/red" : "/rnd",
            "/rnd" : "/red",
            "/contact" : "/contactez",
            "/contactez" : "/contact",
            "/actualites" : "/news",
            "/news" : "/actualites"
        }
        const url = window.location.href.split("/");
        const pageName = "/" + url[url.length-1];

        window.open(pageRel[pageName],"_self")
        return
    };

    const handleFb =() => {
        window.open("https://www.facebook.com/profile.php?id=100078958747286", "_blank")
    }

    const handleYoutube =() => {
        window.open("https://www.youtube.com/@helinovations/featured", "_blank")
    }

    const handleTikTok =() => {
        window.open("https://www.tiktok.com/@helinovations", "_blank")
    }

    const handleMap =() => {
        window.open("https://www.google.ca/maps/place/147+Chem.+des+Raymond,+Rivi%C3%A8re-du-Loup,+QC+G5R+5X9/@47.8448825,-69.5537117,13z/data=!4m5!3m4!1s0x4cbe3f5fa9889abf:0x329050afc6cd312f!8m2!3d47.8359675!4d-69.5016876", "_blank")
    }

    return (
        <IconContext.Provider value={{ style: {fontSize: '30px', margin: '0 0.6pc'}}}>
        <div className='app-footer' >
                <div ><MdCopyright />2022 </div>
                <div onClick={handleMap} style={{display: 'flex', flexDirection:'row', alignContent: 'center'}}>
                    <MdLocationOn className="icon-color" style={{alignSelf: "center"}} /><span >147, ch. des Raymond<br />Rivière-du-Loup, Qc, G5R 5X9</span>
                </div>
                <div ><GiRotaryPhone />418-863-5904</div>
                <div style={{display: 'flex', flexDirection:'column'}}>
                    <div style={{display: 'flex', flexDirection:'row', alignContent: 'center'}}>
                        <div onClick={handleFb}><MdFacebook className="icon-color" /></div>
                        <div onClick={handleYoutube}><FaYoutube className="icon-color" /></div>
                        <div onClick={handleTikTok}><FaTiktok className="icon-color" /></div>
                    </div>
                </div>
                <div onClick={changeLanguage} style={{cursor: 'pointer'}}>
                    {i18n.language === "en" ? "Français" : "English"}
                </div>    
        </div>
        </IconContext.Provider>
    );
};

export default Footer;