import React from "react";
import { withTranslation } from 'react-i18next';

class article extends React.Component {
    constructor(props) {  
        super(props);

      
        this.state = {      
            imgMaxWidth: 0,
            wWidth : window.innerWidth
        };  
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }
      
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }
      
    updateWindowDimensions = () => {
        this.setState({ wWidth: window.innerWidth });
    }

    render() {
        let minWinSize = 700;
        let pic = this.props.pic;
        let imgFloat = this.state.wWidth <= minWinSize ? 'unset' : this.props.imgFloat;
        let imgMargL = imgFloat === "left" || this.state.wWidth <= minWinSize ? "0" : "0.6pc";
        let imgMargR = imgFloat === "right" || this.state.wWidth <= minWinSize ?  "0" : "0.6pc";
        let _title = this.props.title;
        let titleType = this.props.isHeader ? "article-header" : "article-title";
        let picSize = this.state.wWidth <= minWinSize ? '100%' : "picSize" in this.props ? this.props.picSize : '45%';
        let asImg = "pic" in this.props;

        const onImgLoad = ({ target: img }) => {
            const { naturalWidth } = img;
            this.setState({imgMaxWidth: naturalWidth})
          };
         
        return(
            <div className="article-background">
                <h1 className={titleType}>{this.props.t(_title+"_title")}</h1>
                <div className='article-body'>
                <img src={pic} onLoad={onImgLoad} alt={'helinov_' + this.props.t(_title+"_title")} style={{display: asImg ? 'block' : 'none', float: imgFloat, marginTop: '5px', marginLeft: imgMargL, marginRight: imgMargR, objectFit:"scale-down", width: picSize, maxWidth: this.state.imgMaxWidth, alignSelf: "flex-start"}}/>
                <div dangerouslySetInnerHTML={{ __html: this.props.t(_title+"_details")}}  />
                </div>
            </div>
        );
    }
}

export default withTranslation()(article);