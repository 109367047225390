import React from "react";
import "../styles/contact.css";
import { withTranslation } from 'react-i18next';
import { MdLocationOn } from "react-icons/md";
import { GiRotaryPhone } from "react-icons/gi";
import i18n from '../i18n';
import { Helmet } from 'react-helmet';

class ContactForm extends React.Component {

    constructor(props) {
      super(props);
      this.state = {
        name: '',
        nameError: false,
        email: '',
        emailError: false,
        emailError2: false,
        message: '',
        messageError: false,
        formValid: false,
        emailSuccess: false,
        emailConfirm: false,
        emailSent: false,
        imgMaxWidth: 1600
      };
  
      this.handleChange = this.handleChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
      this.handleBlur   = this.handleBlur.bind(this);
    }
    
    clearForm() {
      this.setState({ name: '' });
      this.setState({ email: '' });
      this.setState({ message: '' });
    }

    sendMail = async () => {
        this.setState({ emailConfirm: false });
        this.setState({ emailSent: true });
        const { name, email, message } = this.state;
         let details = {
            'name': name,
            'email': email,
            'message': message,
            'subject': this.props.t("contact_subject")
        };

        let response = await fetch("/server/contact", {
            method: "POST",
            headers: {
                "Content-Type": "application/json;charset=utf-8",
            },
            body: JSON.stringify(details),
        });
        
        let result = await response.json();

        this.setState({ emailConfirm: true });
        this.setState({ emailSuccess: result['status']==='Message Sent' });
        this.setState({ emailSent: false });

        window.scrollTo({
          top: 0,
          behavior: 'smooth' // for smoothly scrolling
        });

        this.clearForm();
    };
    
    isValidEmail(email) {
      return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
    }
    
    handleBlur(e) {
      const name = e.target.name; 
      const value = e.target.value;

      this.setState({ [name]: value });
      /*
      if (value.length <= 0 && (name === 'name')) {
        this.setState({ nameError: true });
      } else {
        this.setState({ nameError: false });
      } 

      if (value.length <= 0 && (name === 'email')) {
        this.setState({ emailError: true });
        this.setState({ emailError2: false });
      } else {
        this.setState({ emailError: false });
        if(this.isValidEmail(value)) {
          this.setState({ emailError2: false });  
        } else {
          this.setState({ emailError2: true });  
        }
      } */
    }
    
    handleChange(e) {
      this.setState({ [e.target.name]: e.target.value  });
      this.setState({ emailSent: false });
    }
  
    handleSubmit(e) {
      let { name, email, message } = this.state;
      let asErr = false;

      if (name.length <= 0) {
        this.setState({ nameError: true });
        asErr = true;
      } else {
        this.setState({ nameError: false });
      };

      if (email.length <= 0) {
        this.setState({ emailError: true });
        this.setState({ emailError2: false });
        asErr = true;
      } else {
        this.setState({ emailError: false });
        if(this.isValidEmail(email)) {
          this.setState({ emailError2: false });  
        } else {
          this.setState({ emailError2: true }); 
          asErr = true; 
        }
      }

      if (message.length <= 0) {
        this.setState({ messageError: true });
        asErr = true;
      } else {
        this.setState({ messageError: false });
      };

      if (!asErr) {
        this.setState({ formValid: true });

        //setStatus("Sending...");
                
        this.sendMail()
        
      } else {
        this.setState({ formValid: false });
      }

      e.preventDefault(); 
    }
    
    render() {
      if(this.props.language !== i18n.language){
          i18n.changeLanguage(i18n.language === 'fr' ? 'en' : 'fr')
      }

      let contactImg = process.env.PUBLIC_URL + "pictures/home_2.jpg";

        const onImgLoad = ({ target: img }) => {
            const { naturalWidth } = img;
            this.setState({imgMaxWidth: naturalWidth})
        };

        const { nameError, emailError, emailError2, messageError, emailSent } = this.state;
        
        const handleMap =() => {
          window.open("https://www.google.ca/maps/place/147+Chem.+des+Raymond,+Rivi%C3%A8re-du-Loup,+QC+G5R+5X9/@47.8448825,-69.5537117,13z/data=!4m5!3m4!1s0x4cbe3f5fa9889abf:0x329050afc6cd312f!8m2!3d47.8359675!4d-69.5016876", "_blank")
        } 

      /*if(!formValid) {*/
      return (
    <div className='page-body' >
      <Helmet>
          <html lang={i18n.language} />
          <title>{this.props.t("company_title") + " - " + this.props.t("contact_title")}</title>
          <meta name="Description" content={this.props.t("meta_description")} />
          <meta name="Keywords" content={this.props.t("meta_keywords")} />
          <link rel="schema.DC" href="http://purl.org/dc/elements/1.1/" />
          <meta name="DC.title" content={this.props.t("operations_title")} />
          <meta name="DC.Description" content={this.props.t("meta_description")} />
          <meta name="DC.Subject" content={this.props.t("meta_keywords")} />
          <meta name="DC.format" content="text/html" />
          <meta name="DC.language" scheme="RFC3066" content={ i18n.language === 'fr' ?  "fr-ca" : "en-ca"} />
          <meta content={i18n.language} http-equiv="content-language" />
      </Helmet>
      {
        window.location.pathname==="/contactez" ?
        <Helmet>
            <link href={"https://helinov.ca/contactez"}  rel="canonical" />
        </Helmet>
        :
        <Helmet>
            <link href={"https://helinov.ca/contact"} rel="canonical" />
        </Helmet>
      } 
    <div className="alert alert-success mt-3" style={{display: this.state.emailConfirm & this.state.emailSuccess ? 'block' : 'none'}}>{this.props.t("contact_message_sent")}</div>
    <div className="alert alert-danger mt-3" style={{display: this.state.emailConfirm & !this.state.emailSuccess ? 'block' : 'none'}}>{this.props.t("contact_message_error")}</div>
    
    <div className='contact-body' style={{marginTop: '1pc'}}>
        <img src={contactImg} onLoad={onImgLoad}  alt={'helinov_' + this.props.t("contact_title")}  style={{boxShadow: '2px 2px 3px var(--red-heli)', float: 'left', objectFit:"scale-down", alignSelf: "flex-start", maxWidth: this.state.imgMaxWidth}}/>

        <div className="contact-card contact-form" >
            <div >
                <MdLocationOn onClick={handleMap} style={{cursor: 'pointer', color: 'var(--red-heli)',height:'40px', width:'40px'}} />
                <span style={{fontWeight:'bold', fontSize:'1.5em'}}>Hélinovations inc.</span><br />
                <span style={{marginLeft:'40px'}}>147, ch. des Raymond</span><br /><span style={{marginLeft:'40px'}}>Rivière-du-Loup, Qc, G5R 5X9</span><br /><br />
                <GiRotaryPhone style={{color: 'var(--red-heli)', height:'40px', width:'40px'}} />418-863-5904
                <br /><br />
                <form action="/" onSubmit={(e) => this.handleSubmit(e)} encType="multipart/form-data" autoComplete="on" className="contact-form">
                <div className="form-group">
                    <label className="mt-3 mb-0 fw-bold">{this.props.t("contact_your_name")}<span className="text-danger">*</span></label>
                    <input name="name" type="text" className="form-control" placeholder={this.props.t("contact_name")} value={this.state.name} onChange={this.handleChange} onBlur={this.handleBlur} />
                    { nameError
                    ? <div className="alert alert-danger mt-2">{this.props.t("contact_name_required")}</div>
                    : ''
                    }
                </div>
                <div className="form-group">
                    <label className="mt-3 mb-0 fw-bold">{this.props.t("contact_your_email")}<span className="text-danger">*</span></label>
                    <input name="email" type="email" className="form-control" placeholder={this.props.t("contact_email")} value={this.state.email} onChange={this.handleChange} onBlur={this.handleBlur} />
                    { emailError
                    ? <div className="alert alert-danger mt-2">{this.props.t("contact_email_required")}</div>
                    : ''
                    }
                    { emailError2
                    ? <div className="alert alert-danger mt-2">{this.props.t("contact_email_invalid")}</div>
                    : ''
                    }
                </div>

                <div className="form-group">
                    <label className="mt-3 mb-0 fw-bold">{this.props.t("contact_your_message")}<span className="text-danger">*</span></label>
                    <textarea name="message" type="text" className="form-control" placeholder={this.props.t("contact_message")} value={this.state.message} onChange={this.handleChange} onBlur={this.handleBlur} />
                    { messageError
                    ? <div className="alert alert-danger mt-2">{this.props.t("contact_message_required")}</div>
                    : ''
                    }
                </div>
                    <p className="text-center mb-0" style={{marginTop: '0.6pc'}}><input disabled={((nameError || emailError || emailError2 || messageError) && !emailSent) || emailSent } type="submit" className="btn btn-lg w-100 text-uppercase " style={{backgroundColor:'var(--red-heli)', maxWidth:'300px', fontWeight: 'bold'}} value={this.props.t("contact_submit")} /></p>
                </form>
                
            </div>
        </div>
      </div>
      </div>
      );
       /* } else {

         return (
           <div className="page-body">
            <div className="alert alert-success mt-3">{this.props.t("contact_message_sent")}</div>
            <img src={bgImg} alt='' />
           </div>
          );


        }*/
    }
  }
  
  export default withTranslation()(ContactForm);
