import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import "./styles/app.css";
import Home from './pages/home';
import Forest from './pages/forest';
import Deicing from './pages/deicing';
import Construction from './pages/construction';
import RnD from './pages/rd';
//import MyFacebook from './pages/facebook';
import School from './pages/school';
import ContactForm from './pages/contact';
import Footer from './components/footer'
import 'bootstrap/dist/css/bootstrap.min.css';
import { default as Navbar } from "./components/navbar";
import { MdVerticalAlignTop } from 'react-icons/md';

function App() {

  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 75) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    });
  }, []);

  // This function will scroll the window to the top 
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // for smoothly scrolling
    });
  };
  let bgImg = process.env.PUBLIC_URL + "pictures/baie_james.jpg";
  return (
    <>
    <div className="app" style={{
        backgroundImage: `url(${bgImg})`,
        }}
    >
      <BrowserRouter>
      <Navbar />
          <Routes>
            <Route path="/home" element={<Home language='en'/>} />
            <Route path="/forest" element={<Forest language='en'/>} />
            <Route path="/deicing" element={<Deicing language='en'/>} />
            <Route path="/work" element={<Construction language='en'/>} />
            <Route path="/rnd" element={<RnD language='en'/>} />
            <Route path="/school" element={<School language='en'/>} />
            <Route path="/contact" element={<ContactForm language='en'/>} />

            {/*
            <Route path="/news" element={<MyFacebook language='en'/>} />
            <Route path="/actualites" element={<MyFacebook language='fr'/>} />
            */}

            <Route exact path="/" index element={<Home language='fr'/>} />
            <Route path="/foret" element={<Forest language='fr'/>} />
            <Route path="/deglacage" element={<Deicing language='fr'/>} />
            <Route path="/construction" element={<Construction language='fr'/>} />
            <Route path="/red" element={<RnD language='fr'/>} />
            <Route path="/ecole" element={<School language='fr'/>} />
            <Route path="/contactez" element={<ContactForm language='fr'/>} />
            <Route path="*"  element={ <Navigate to="/" /> } />
          </Routes>
      </BrowserRouter>
      <Footer />
    </div>
    {showButton && (
        <MdVerticalAlignTop onClick={scrollToTop} className="back-to-top" style={{ alignItems: 'center'}} />
    )}
    </>
  );
}

export default App;
